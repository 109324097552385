header {
    background: rgb(243,157,34);
    background: linear-gradient(0deg, rgba(243,157,34,1) 0%, rgba(248,197,10,1) 100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 20px;
}

.hero_text {
    width: 350px
}

.hero_image img {
    width: 500px;
}

.sup {
    position: relative;
    bottom: 1ex; 
    font-size: 80%;
}


.b3xlogo {
    width: 20em;
    margin-bottom: 20px;
}


.event_title {
    font-weight: 700;
    font-size: 3.5rem;
    color: #000;
}

.event_title_expand {
    color: #000;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 20px;
    letter-spacing: 4px;
}

.event_theme {
    color: #000;
    margin-bottom: 15px;
    font-weight: 600;
}


.event_date, .event_time {
    color: #000;
    font-weight: 600;
    margin-bottom: 5px;
}

.header_register_button {
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin-top: 30px;
    cursor: pointer;
    font-weight: 600;
}

.header_register_button:hover {
    background-color: #fff;
    color: var(--pri-clr);
}


@media screen and (max-width: 425px) {
    header {
        padding: 20px 20px 0 20px;
        flex-direction: column;
        text-align: center;
    }

    .hero_text {
        width: 100%
    }

    .b3xlogo {
        display: block;
        margin: 20px auto;
    }
    
    .event_title {
        font-size: 2rem;
    }
    .event_title_expand {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 768px) {
    .event_title {
        font-size: 2.5rem;
    }
}