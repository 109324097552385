.ticket {
    background: #2f2f2f;
    padding: 60px 0;
}

.ticket h2 {
    color: var(--pri-clr);
    text-align: center;
    font-weight: 600;
    margin-bottom: 40px;
    padding-bottom: 20px;
    margin-bottom: 40px;
    position: relative;
}


.ticket h2::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 60px; /* Adjust the width of the dash */
    height: 3px; /* Adjust the height of the dash */
    background-color: #fff; /* Adjust the color of the dash */
}

.ticket-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    flex-basis: 0;
    gap: 20px;
}

.ticket-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 6px;
    padding: 10px;
    width: max-content;
}

.ticket-name {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
    color: var(--pri-clr);
}

.ticket-price {
    background-color: rgb(110, 63, 167);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    font-size: 1rem;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 10px;
}

.ticket-price-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
}


.ticket-price-group div:nth-child(1) {
    background-color: aquamarine;
    padding: 5px;
    border-radius: 5px;
    flex: 1;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;

}

.ticket-price-group div:nth-child(2) {
    background-color:rgb(255, 195, 195);
    padding: 5px;
    border-radius: 5px;
    flex: 1;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    
}


.ticket-point-item {
    margin: 10px 0;
    border-bottom: 1px solid #989898;
    color: #2f2f2f;
    
}

.ticket-point-item:nth-last-child(1){
   margin-bottom: 20px;
    
}


.ticket-button {
    border: none;
    border-radius: 5px;
    text-align: center;
    margin-top: auto;
    display: block;
    background-color: var(--pri-clr);
    padding: 10px;
    text-decoration: none;
    color: #000;
    font-weight: 600;
}

.sponsorship-packages {
    color: #fff;
    margin: 40px auto;
    text-align: center;
}

.contact-clr {
    color: var(--pri-clr);
}

@media screen and (max-width: 428px) {
    .ticket-group {
        flex-direction: column;
        align-items: center;
    }

    .ticket-item {
        width: 100%
    }
}