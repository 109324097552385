.event_topic {
    background: #2f2f2f;
    padding: 60px 0;
}

.event_topic h2 {
    color: var(--pri-clr);
    text-align: center;
    font-weight: 600;
    margin-bottom: 40px;
    padding-bottom: 20px;
    margin-bottom: 40px;
    position: relative;
}


.event_topic h2::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 60px; /* Adjust the width of the dash */
    height: 3px; /* Adjust the height of the dash */
    background-color: #fff; /* Adjust the color of the dash */
}

.event_topic_group {
    color: #f2f2f2;
    text-align: center;
}

.event_topic_item {
    margin: 10px 0;
}