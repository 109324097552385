.speakers_list {
    /* background: rgb(243,157,34);
    background: linear-gradient(0deg, rgba(243,157,34,1) 0%, rgba(248,197,10,1) 100%); */
    padding: 60px 0;
    background-color: #fcfcfc;
}

.speakers_list h2 {
    text-align: center;
    font-weight: 600;
    padding-bottom: 20px;
    margin-bottom: 40px;
    position: relative;
}

.speakers_list h2::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 60px; /* Adjust the width of the dash */
    height: 3px; /* Adjust the height of the dash */
    background-color: var(--sec-clr);
}

.speaker_group {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    padding: 16px;
    justify-content: center;
    align-items: center;
}

.speaker_image {
    border-radius: 8px;
}

.speaker_title {
    color: var(--pri-clr);
    font-weight: 600;
    font-size: 1rem;
    margin-top: 20px;
}

.speaker_position {
    font-size: 0.8rem;
    color:#4d4d4d;
}

.speaker_company {
    font-size: 0.8rem;
    color:#8e8e8e;
}

@media screen and (max-width: 428px) {
    .speaker_group {
        text-align: center;
    }

    .speaker_item {
        margin: 0 auto;
        width: 200px;
    }
}

