.event_highlight {
    background-image: url('https://images.unsplash.com/photo-1527261834078-9b37d35a4a32?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    padding: 60px 0;
}

.event_highlight h2 {
    color: var(--sec-clr);
    text-align: center;
    font-weight: 600;
    padding-bottom: 20px;
    margin-bottom: 40px;
    position: relative;
}

.event_highlight h2::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 60px; /* Adjust the width of the dash */
    height: 3px; /* Adjust the height of the dash */
    background-color: var(--sec-clr); /* Adjust the color of the dash */
}

.event_highlight_group {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.event_highlight_item {
    background-color: #f2f2f2;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    width: 200px;
    border-radius: 5px;
    font-weight: 600;
    padding: 18px;
}